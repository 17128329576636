<template>
  <el-dialog
    id="FindOrgInTreeDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="80%"
    close-on-press-escape
    destroy-on-close
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
  >
    <el-container>
      <div ref="flex_container" class="flex_container">
        <el-header class="header3" height="48"
          ><el-row type="flex" justify="start">
            <div>
              <el-input
                :placeholder="$t('Пошук')"
                style="width:308px;"
                prefix-icon="el-icon-search"
                clearable
                v-model="filterString"
                @input="onFilterStringChange"
                ref="filter"
                @keydown.esc.native="resetFilter($event, this)"
                @keydown.enter.native="runFilter($event, this)"
              />
            </div>
          </el-row>
        </el-header>

        <ag-grid-vue
          ref="findOrgTable"
          id="findOrgTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="multiple"
          treeData="true"
          :groupDefaultExpanded="-1"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="tree"
          :gridOptions="gridOptions"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @selection-changed="onGridSelectionChanged"
          @gridReady="onGridReady"
          :modules="modules"
          :getDataPath="getDataPath"
          :autoGroupColumnDef="autoGroupColumnDef"
          :enableBrowserTooltips="true"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          :localeTextFunc="gridLocale"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedRows || !selectedRows.length">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";
// import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
// import { RowGroupingModule } from "@ag-grid-enterprise/row-grouping";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "FindOrgInTree",
  components: { AgGridVue, Icon, TIButton, TSeparator, GridTotalBottomRow },
  data() {
    var that = this;
    return {
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      model: {},
      initModel: {},
      filterString: "",
      selectedRows: null,
      selectedOrgs: [],
      keyUpTimer: null,
      pinnedBottomRowData: null,
      modules: AllModules,
      frameworkComponents: null,
      getDataPath: (data) => {
        return data.path;
      },
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      gridApi: null,
      gridColumnApi: null,
      gridOptions: {
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        // valueGetter: function(params) {
        //   return params.data.name;
        // },
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if (nodeA.data.okpo != "" && nodeB.data.okpo == "") return -1;
          if (nodeA.data.okpo == "" && nodeB.data.okpo != "") return 1;
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      columns: [
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 1,
        },
        {
          headerName: "AxiomaID",
          field: "axiomaId",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          width: "130px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
          filter: "agSetColumnFilter",
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
          filter: "agSetColumnFilter",
        },
        {
          headerName: $tt("Категорія"),
          field: "srcCategory",
          width: "50px",
          filter: "agSetColumnFilter",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  created() {
    bus.$on("forms.orgsInTree.find.show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.operation = (data && data.operation) || "create";
        this.initModel = _.extend({}, data ? data.initModel : {});
        this.filterString = "";
        this.selectedOrgs = data && data.selectedOrgs ? data.selectedOrgs : [];
        this.selectedOrgs.forEach((item) => {
          var node = that.gridOptions.api.getRowNode(item.axiomaId);
          if (node && !node.selected) node.setSelected(true);
        });
        this.dialogShow = true;
      }
    });
    bus.$on("forms.orgsInTree.find.hide", () => {
      this.dialogShow = false;
    });
  },
  computed: {
    tree() {
      return this.$store.getters["contracts/contractStructuresWithParents"](this.filterString);
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 48;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container.style.width = `100%`;
      this.$refs.flex_container.children[0].children[0].children[0].style.height = `${containerHeight - 48}px`;
    },
    orgs() {
      this.pinnedBottomRowData = { name: $tt("Рядків знайдено: {len}", { len: this.orgs.length }) };
    },
  },
  beforeMount() {
    this.frameworkComponents = {
      //customPinnedRowRenderer: GridTotalBottomRow,
      GridCellWithIcons: GridCellWithIcons,
    };
  },

  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("findOrgInTreeGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        this.$refs.filter.clear();
      }
    },
    runFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
      }
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.$refs.filter.focus();
    },
    onClose() {
      this.resetFilter();
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.selectedRows) {
        this.$emit("find-commit", { target: this.target, result: this.getSelectedRowsWithParents() });
        this.onClose();
      } else this.$message.error($tt("Будь ласка, виберіть хочаб один рядок!"));
    },
    onFilterStringChange(value, timeout) {
      var that = this;
    },
    onGridSelectionChanged() {
      var that = this;
      var rows = this.gridOptions.api.getSelectedRows();
      var addIds = [];
      var delIds = [];
      if (!this.selectedRows) {
        addIds = rows.map((item) => item.axiomaId);
      } else {
        var oldSelectedIds = this.selectedRows.map((item) => item.axiomaId);
        var newSelectedIds = rows.map((item) => item.axiomaId);
        addIds = newSelectedIds.filter((x) => !oldSelectedIds.includes(x));
        delIds = oldSelectedIds.filter((x) => !newSelectedIds.includes(x));
      }
      if (addIds.length > 0) {
        this.tree.forEach((item) => {
          var intersect = item.path.filter((value) => addIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptions.api.getRowNode(item.axiomaId);
            if (node && !node.selected) node.setSelected(true);
          }
        });
      } else if (delIds.length > 0) {
        this.tree.forEach((item) => {
          var intersect = item.path.filter((value) => delIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptions.api.getRowNode(item.axiomaId);
            if (node && node.selected) node.setSelected(false);
          }
        });
      }
      this.selectedRows = rows;
    },
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { findOrgInTreeGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    getSelectedRowsWithParents() {
      var parents = [];
      var pathIds = [];
      this.selectedRows.forEach((item) => {
        pathIds = pathIds.concat(item.path);
        /*var parentsByPath = this.tree.filter((org) => {
            return (
              item.path.findIndex((pathItem) => {
                return pathItem[0] == org.axiomaId;
              }) != -1
            );
          });
          parents = parents.concat(parentsByPath.filter((newParent) => parents.find((oldParent) => oldParent.axiomaId != newParent.axiomaId)));*/
      });
      parents = this.tree.filter((org) => {
        return pathIds.find((path) => path[0] == org.axiomaId);
      });
      return parents; //this.selectedRows.concat(parents));
      //this.selectedRows.concat(parents);
      //this.selectedRows.concat(parents.filter((newParent)=>this.selectedRows.find((oldParent)=>oldParent.axiomaId!=newParent.axiomaId)));
    },
  },
};
</script>

<style lang="scss" scope>
#FindOrgInTreeDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#FindOrgInTreeDialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}

#FindOrgInTreeDialog .el-loading-mask {
  top: 48px !important;
  /*bottom: -48px !important;*/
}

i.w0 {
  width: 10px !important;
}
</style>
